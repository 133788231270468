import * as React                  from "react"
import { graphql, useStaticQuery } from "gatsby";
import Layout                  from "../components/layout";
import { useEffect, useState } from "react";
import CreateGalleryPopup      from "../components/createGalleryPopup";
import GalleryOrderPopup       from "../components/galleryOrderPopup";
import ImageTile               from "../components/imageTile";
import ActionTile              from "../components/actionTile";
import config                  from "../config";
import { getToken }            from "../utilities/authController";

import '../styles/gallery.scss';
import useWindowSize           from "../hooks/useWindowSize";
import RenameGalleryPopup      from "../components/renameGalleryPopup";

const deleteGallery = async (galleryName) => {
	const token = await getToken();

	await fetch(`${ config.apiUrl }/gallery/${ encodeURI(galleryName) }`, {
		method: "delete",
		headers: {
			'Authorization': `${token}`
		}
	});
}

const GalleryPage = () => {
	const data = useStaticQuery(graphql`
	{
		allS3SourcedGallery {
			nodes {
				GalleryName
				Order
				childrenS3SourcedImage {
					Name
					Key
					isPrimary
					remoteImage {
						childImageSharp {
							gatsbyImageData(layout: CONSTRAINED, width: 500)
						}
					}
				}
			}
		}
	}`
	);

	const [galleries, setGalleries] = useState(data.allS3SourcedGallery.nodes.map(el => {
		return {
			GalleryName: el.GalleryName,
			Order: el.Order,
			image: (el.childrenS3SourcedImage) ? el.childrenS3SourcedImage.find((item) => item.isPrimary).remoteImage.childImageSharp.gatsbyImageData || null : null,
			isDynamic: false
		}
	}).sort((a, b) => {
		return b.Order - a.Order;
	}));

	const size = useWindowSize();

	const [isEditMode, setIsEditMode] = useState(false);
	const [isPopupDisplayed, setIsPopupDisplayed] = useState(false);
	const [isOrderPopupDisplayed, setIsOrderPopupDisplayed] = useState(false);
	const [changeNamePopupValue, setChangeNamePopupValue] = useState(false);

	const loadEdit = async () => {
		const token = await getToken();

		console.log(token);

		const rawData = await fetch(`${ config.apiUrl }/gallery`, {
			method: "get",
			headers: {
				'Authorization': `${token}`
			}
		});

		const Galleries = (await rawData.json()).galleries;

		console.log(Galleries);

		const part1 = galleries.map(el => {
			return {
				...el,
				isDeleted: !(Galleries.findIndex(dynEl => dynEl.GalleryName === el.GalleryName) > -1)
			};
		}).filter(el => (!(el.isDeleted) || !(el.isDynamic)));

		console.log(part1);

		const part2 = Galleries.filter(dynEl => {
			return !(galleries.findIndex(el => dynEl.GalleryName === el.GalleryName) > -1);
		})
		.map(el => {
			return {
				...el,
				isDynamic: true
			};
		});

		console.log(part2);

		setGalleries(part1.concat(part2));
	}

	const openCreateGalleryPopup = () => {
		setIsPopupDisplayed(true);
	}

	useEffect(() => {
		if(isEditMode) {
			loadEdit().catch(console.error);
		}

		return () => {};
	}, [isEditMode]);

	const gridSize = Math.min(Math.max(Math.round((size.width - 300)/300), 1), 4) || 4;

	const cols = [];

	for(const _ of Array(gridSize)) {
		cols.push([]);
	}

	galleries.forEach((e, index) => {
		cols[index % gridSize].push(e);
	})

	return (
		<Layout onAuthenticatedChange={(state) => setIsEditMode(state)} title={`Jędrek Wojnar F.o.t.o.g.r.a.f.i.a | Albumy `}>
			<h1>
				Albumy
				{isEditMode ? (<> (tryb edycji)<button className={'button'} onClick={() => setIsOrderPopupDisplayed(true)}>Zmień kolejność</button></>) : null}
			</h1>
			<div className={'image-grid'} style={{gridTemplateColumns: `repeat(${gridSize}, 1fr)`}}>
				{ cols.map((col, colNr) => {
					return (
						<div key={colNr} className={'column'}>
							{
								col.map(el => {
									return (
										<ImageTile
											key={el.GalleryName}
											image={el.image}
											isDeleted={el.isDeleted}
											to={el.isDynamic ? `/gallery-preview/?name=${el.GalleryName}` : el.GalleryName}
											label={el.GalleryName}
											url={el?.Image ? el?.Image?.Url : ''}
											isDynamic={el.isDynamic}
											isEditMode={isEditMode}
											onDelete={async () => { await deleteGallery(el.GalleryName); loadEdit().catch(console.error)}}

										/>
									);
								})
							}
							{(isEditMode && colNr === (gridSize - 1)) ? (<ActionTile onClick={() => openCreateGalleryPopup()}>+</ActionTile>) : (<></>)}
						</div>
					)
				}) }
			</div>
			{ isPopupDisplayed ? (<CreateGalleryPopup onClose={(isUploaded) => {
				setIsPopupDisplayed(false);
				if(isUploaded) {
					loadEdit().catch(console.error);
				}
			}} />) : (<></>) }
			{ isOrderPopupDisplayed ? (<GalleryOrderPopup onClose={() => {
				setIsOrderPopupDisplayed(false);
			}} />) : (<></>) }
			{ changeNamePopupValue ? (<RenameGalleryPopup galleryName={changeNamePopupValue} onClose={(isUploaded) => {
				setChangeNamePopupValue(false);
				if(isUploaded) {
					loadEdit().catch(console.error);
				}
			}} />) : (<></>) }
		</Layout>
	)
}

export default GalleryPage;
