import { CognitoUserPool } from "amazon-cognito-identity-js";
import config              from "../config";

export class AuthController {
	static _isAuthenticated = false;

	static login(email, password) {

	}

	static logout() {

	}

	static get isAuthenticated() {
		return this._isAuthenticated;
	}
}

export const getToken = () => {
	return new Promise((resolve, reject) => {
		const userPool = new CognitoUserPool({
			UserPoolId: config.UserPoolId,
			ClientId: config.ClientId,
		});

		const user = userPool.getCurrentUser();

		user.getSession((error, session) => {
			if(error){
				reject(error)
			}
			resolve(session.getIdToken().getJwtToken());
		})
	});
}
