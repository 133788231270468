import * as React      from "react";

const plusTileStyle = {
	textAlign: "center",
	background: "linear-gradient(45deg, rgba(71,201,229,0.9) 0%, rgba(102,252,218,0.9) 100%)",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	flexDirection: "column",
	alignContent: "stretch",
	cursor: "pointer",
	color: "white",
	padding: "0 0 25px",
	aspectRatio: 1
}
const plusTileIconStyle = {
	fontSize: "10vw",
	fontWeight: "lighter",
}

const ActionTile = ({ onClick, children }) => {


	return (
		<div style={plusTileStyle} onClick={(event) => onClick(event)}>
			<span style={plusTileIconStyle}>{children}</span>
		</div>
	);
}

export default ActionTile;
