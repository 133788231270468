import * as React          from "react"
import PopupLayout         from "./popupLayout";
import config                  from "../config";
import { useEffect, useState } from "react";
import { getToken }            from "../utilities/authController";

const saveOrder = async (list) => {
	const token = await getToken();

	console.log(list);

	await fetch(`${ config.apiUrl }/gallery`, {
		method: 'post',
		body: JSON.stringify(list),
		headers: {
			'Authorization': token
		}
	});
}

const getGalleries = async () => {
	const token = await getToken();

	const res = await fetch(`${ config.apiUrl }/gallery`, {
		method: 'get',
		headers: {
			'Authorization': token
		}
	});

	return (await res.json()).galleries;
}

const GalleryOrderPopup = ({onClose}) => {
	const [order, setOrder] = useState([]);

	useEffect(() => {
		(async () => {
			const gal = await getGalleries();
			console.log(gal);

			const o = gal.map((el) => {
				return {
					order: el.Order,
					name: el.GalleryName
				}
			}).sort((a, b) => b.order - a.order);

			console.log(o);

			setOrder(o);
		})();
	}, []);

	return (
		<PopupLayout title="Kolejność galerii" onClose={(result) => onClose(result)}>
			<ul>
				{
					order.map((el, i) => {
						return (<li key={el.name}>
							<span style={{width: 'calc(100% - 100px)', display: 'inline-block'}}>{el.name}</span>
							{i !== 0 ? <button onClick={() => {
								const newOrder = [...order];
								console.log('swap', i, i-1, newOrder[i].order, newOrder[i - 1].order);
								[newOrder[i].order, newOrder[i - 1].order] = [newOrder[i - 1].order, newOrder[i].order];
								setOrder(newOrder.sort((a, b) => b.order - a.order));
							}}>up</button> : null}
							{i + 1 !== order.length ? <button onClick={() => {
								const newOrder = [...order];
								console.log('swap', i, i+1, newOrder[i].order, newOrder[i + 1].order);
								[newOrder[i].order, newOrder[i + 1].order] = [newOrder[i + 1].order, newOrder[i].order];
								setOrder(newOrder.sort((a, b) => b.order - a.order));
							}}>down</button> : null}
						</li>);
					})
				}
			</ul>
			<button onClick={async () => {
				await saveOrder(order);
				onClose(true);
			}}>Zapisz zmiany</button>
		</PopupLayout>
	)
};

export default GalleryOrderPopup;
