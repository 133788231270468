import * as React          from "react"
import PopupLayout         from "./popupLayout";
import config              from "../config";
import { useState }        from "react";
import { getToken }        from "../utilities/authController";

const renameGallery = async (galleryName, newGalleryName) => {
	const token = await getToken();

	await fetch(`${ config.apiUrl }/gallery`, {
		method: 'patch',
		body: JSON.stringify({
			name: galleryName,
			newName: newGalleryName
		}),
		headers: {
			'Authorization': token
		}
	})
}

const RenameGalleryPopup = ({galleryName, onClose}) => {
	const [newGalleryName, setNewGalleryName] = useState(galleryName)

	return (
		<PopupLayout title={`Zmień nazwę galerii ${galleryName}`} onClose={(result) => onClose(result)}>
			<input value={newGalleryName} placeholder="Nazwa galerii" type="text" onChange={(event => setNewGalleryName(event.target.value))} />
			<button onClick={async () => {
				await renameGallery(galleryName, newGalleryName);
				onClose(true);
			}}>Zmień nazwę</button>
		</PopupLayout>
	)
};

export default RenameGalleryPopup;
