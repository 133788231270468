import * as React   from "react"
import ReactDOM     from "react-dom";
import { useState } from "react";

import "../styles/popup.scss";

const popupWindowStyle = {
	backgroundColor: "white",
	width: 'calc(min(100%, 600px))',
	height: 'calc(min(100%, 400px))',
	display: 'grid',
	gridTemplateRows: '36px 1fr'
}

const PopupLayout = ({children, title, onClose}) => {
	const [modalRoot] = useState(document.getElementById('modal-root'));

	return ReactDOM.createPortal((
			<div className={'popup'} style={popupWindowStyle}>
				<div style={{position: "relative", textAlign: "center",backgroundColor: "#e4e4e4"}}>
					<span style={{lineHeight: '36px'}}>{title}</span>
					<div style={{position: "absolute", padding: "8px 12px", right: 0, top: 0, cursor: "pointer"}} onClick={() => onClose(false)}>x</div>
				</div>
				<div className={'content'}>
					{children}
				</div>
			</div>
		),
		modalRoot
	)
};

export default PopupLayout;
