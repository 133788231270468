import * as React          from "react"
import PopupLayout         from "./popupLayout";
import config              from "../config";
import { useState }        from "react";
import { getToken }        from "../utilities/authController";

const createGallery = async (galleryName) => {
	const token = await getToken();

	await fetch(`${ config.apiUrl }/gallery`, {
		method: 'put',
		body: JSON.stringify({
			GalleryName: galleryName
		}),
		headers: {
			'Authorization': token
		}
	})
}

const CreateGalleryPopup = ({onClose}) => {
	const [galleryName, setGalleryName] = useState('')

	return (
		<PopupLayout title="Nowa galeria" onClose={(result) => onClose(result)}>
			<input value={galleryName} placeholder="Nazwa galerii" type="text" onChange={(event => setGalleryName(event.target.value))} />
			<button onClick={async () => {
				await createGallery(galleryName)
				onClose(true);
			}}>Dodaj galerię</button>
		</PopupLayout>
	)
};

export default CreateGalleryPopup;
