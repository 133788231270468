import { Link }        from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import * as React      from "react";
import { useState }    from "react";

import '../styles/imageTile.scss'

const ImageTile = ({ image, isDynamic, to, label, isDeleted, onDelete, isEditMode, url, onClick, onSelectPrimary, isPrimary, onRename }) => {
	const [ isWorking, setIsWorking ] = useState(false);
	onDelete = onDelete || (() => {});
	isPrimary = !!isPrimary;
	onSelectPrimary = onSelectPrimary || (() => {});
	isDeleted = !!isDeleted;
	isEditMode = !!isEditMode;

	const imageElement = isDynamic ? (
		<img src={url} className={ 'image dynamic' } role={'image'} alt={''} />
	) : (
		<GatsbyImage className={'image'} image={image} alt={''} />
	);

	const labelElement = (
		<span className={label ? 'name' : 'name small'}><span>{label ? label : 'Kliknij aby powiększyć'}</span></span>
	);

	const actions = isEditMode ? (
		<div className={'actions'}>
			<div className={'status'}>{isDeleted ? 'Usunięte' : (isDynamic ? 'Dodane' : '')}</div>
			{isDeleted || to ? null : <div><span style={{color: 'white'}}>Okładka albumu</span> <input className={''} checked={isPrimary} type={'checkbox'} onChange={async (event) => {
				event.preventDefault();
				try {
					setIsWorking(true);
					await onSelectPrimary(event.target.value);
				} finally {
					setIsWorking(false);
				}
			}}/></div> }
			{isDeleted ? null : (<div className={'action'} onClick={async (event) => {
				event.preventDefault();
				try {
					setIsWorking(true);
					await onDelete();
				} finally {
					setIsWorking(false);
				}
			} }>Usuń</div>)}
			{onRename && !isDeleted ? <div className={'action'} onClick={async (event) => {
				event.preventDefault();
				onRename();
			} }>Zmień nazwę</div> : null }
		</div>
	) : null;

	return (
		<>
			{
				to ? (
					<Link to={to} className={isWorking ? 'album-tile working link' : 'album-tile link'} onClick={isEditMode ? () => {} : onClick}>
						{imageElement}
						{actions}
						{labelElement}
					</Link>
				) : (
					<div className={isWorking ? 'album-tile working' : 'album-tile'} onClick={isEditMode ? () => {} : onClick}>
						{imageElement}
						{actions}
						{labelElement}
					</div>
				)
			}
		</>
	);
}

export default ImageTile;
